<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-90 center pv3">
                <div class="w-100">
                    <div class="pad16">
                        <p class="pb2 f3 b">
                            Preview page for Bulk upload
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickModalIcon"
                                    @click="modalBulkSEP = true"
                                    style="cursor: pointer; padding-bottom: 1px"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="modalBulkSEP">
                            You can upload your Sales from Excel to make your Account up-to-date. Click
                            <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <div class="flex flex-column gap16 w-100 box-border pa4 mt4" style="border-radius: 8px">
                            <p><img :src="require('@/assets/images/bulkProduct.svg')" /></p>
                            <p class="font20 b">Contacts</p>
                            <p class="font24 blueColor b">50,000</p>
                            <div class="flex gap8">
                                <p class="flex items-center">
                                    <!-- <span
                                        ><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px"
                                    /></span> -->
                                    <span class="f500 pr2" style="color: #06b04d">40 </span>
                                    <span> Contact Successful</span>
                                </p>
                                <p class="flex items-center">
                                    <span><img :src="require('@/assets/images/salesRed.svg')" height="12px" width="12px" /></span
                                    ><span class="b pr2"> 40 </span> Contact with errors
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="pa3 mt3" style="border-radius: 8px">
                        <div v-if="!viewContact">
                            <h3>Here is your Contact update</h3>
                            <div class="flex gap8">
                                <p class="flex items-center">
                                    <!-- <span><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px" /></span> -->
                                    <span class="font-w1 pr2" style="color: #06b04d">40 </span>
                                    <span>Contact Successful</span>
                                </p>
                                <p class="flex items-center">
                                    <span><img :src="require('@/assets/images/salesRed.svg')" height="12px" width="12px" /></span
                                    ><span class="b pr2"> 4 </span> Contact with errors
                                </p>
                            </div>
                        </div>
                        <div class="mt4 flex" style="justify-content: end">
                            <div class="flex gap8">
                                <button @click="handleViewContact()" class="btn2">View Contact</button>
                                <button class="btn-primary">Complete Import</button>
                            </div>
                        </div>
                    </div>

                    <div class="pt3" v-if="viewContact">
                        <div class="flex" style="justify-content: end">
                            <div class="searchContainer2 box-border unZIndexx w-20 mt3" style="position: relative">
                                <img src="@/assets/images/search.svg" alt="" />
                                <input
                                    autocomplete="off"
                                    class="searchBox pl2 w-100"
                                    type="text"
                                    name="entity"
                                    placeholder="Search for customer with name, phone number or email"
                                />
                            </div>
                        </div>

                        <div class="scrollmenu">
                            <table  class="w-100 tl mt3 box-border mb2" cellspacing="0" style="border-radius: 8px">
                                <thead>
                                    <tr class="pv3" style="background-color: #F4F7FF; border-radius: 8px;">
                                        <th class="pb3 pv3 tl pl2 font12 w-10-l">First Name</th>
                                        <th class="pb3 pv3 tl pl2 font12 w-10-l">Last Name</th>
                                        <th class="pb3 pv3 tl pl2 font12 w-10-l">Description</th>
                                        <th class="pb3 pv3 tl pl2 font12 w-10-l">Phone No.</th>
                                        <th class="pb3 pv3 tl pl2 font12 w-10-l">Address</th>
                                        <th class="pb3 pv3 tl pl2 font12 w-10-l">Company</th>
                                        <th class="pb3 tl pv3 pl2 font12 w-10-l">Country</th>
                                        <th class="pb3 tl pv3 pl2 font12 w-20-l">Email Address</th>
                                        <th class="pb3 tr pv3 pl2 font12 pr2">Delete</th>
                                    </tr>  
                                </thead>
                                <tr class="font-w1">
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="Abasiakara" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="Alexander" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="I am a girl" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="0901234567" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="12 Aka road" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="Krystal Electricals" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="Nigeria" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="abasiakara@simplebks.com" /></td>
                                    <td class="pa3 tr w-100">
                                    <div class="flex w-100" style=" justify-content: end;">
                                        <div class="flex justify-center items-center delStyle" > <img :src="require('@/assets/images/trash.svg')" height="12px" width="12px" /></div>
                                    </div>
                                    </td>
                                </tr>
                                <tr class="font-w1">
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="Abasiakara" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="Alexander" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="I am a girl" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="0901234567" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="12 Aka road" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="Krystal Electricals" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="Nigeria" /></td>
                                    <td class="pa2 pr3 tl"><input class="w-100-l  pa2 radius4" type="" placeholder="abasiakara@simplebks.com" /></td>
                                    <td class="pa3 tr w-100">
                                    <div class="flex w-100" style=" justify-content: end;">
                                        <div class="flex justify-center items-center delStyle" > <img :src="require('@/assets/images/trash.svg')" height="12px" width="12px" /></div>
                                    </div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- pagination -->
                        <div class="w-100 center pb5">
                            <div class="flex justify-between items-center pa3 font-w1" style="background: #f6f8fe">
                                <div class="flex items-center">
                                    <div class="pr3-l pr2">Showing</div>
                                    <div
                                        class="flex items-center pa1 box-border"
                                        style="border: 1px solid rgb(151, 145, 145); background: #ffffff"
                                    >
                                        25 &nbsp; <img src="imgs/down-o.svg" alt="" />
                                    </div>
                                    <div class="pl3 db-l dn">items per page</div>
                                </div>
                                <div class="flex">
                                    <div class="active-page-number">1</div>
                                    <div class="pl2 pl3-l">2</div>
                                    <div class="pl2 pl3-l active-page-number">Next</div>
                                    <div class="pl2 pl3-l">End</div>
                                </div>
                            </div>
                        </div>
                        <!-- pagination -->
                    </div>
                </div>
            </div>
        </template></app-wrapper
    >
</template>
<script>
import { ref } from 'vue'
import AppWrapper from '@/layout/AppWrapper'
export default {
    name: 'BulkUpdateContacts',
    components: { AppWrapper },
    setup() {
        const modalBulkSEP = ref(false)
        const viewContact = ref(false)

        const handleViewContact = () => {
            viewContact.value = true
        }

        return {
            modalBulkSEP,
            viewContact,
            handleViewContact,
        }
    },
}
</script>
